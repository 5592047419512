import logo_html from '../uires/logos/html_logo.svg';
import logo_js from '../uires/logos/js_logo_unofficial_2.svg';
import logo_cpp from '../uires/logos/cpp_logo.svg';
import logo_clang from '../uires/logos/clang_logo.svg';
import logo_android from '../uires/logos/android_robot.svg';
import logo_ang from '../uires/logos/angular_logo.svg';
import logo_node from '../uires/logos/node_logo.svg';
import logo_react from '../uires/logos/react-logo.svg';
import logo_python from '../uires/logos/python-logo-notext.svg';
// import logo_tf from '../uires/logos/tf_logo.svg';
// import logo_pytorch from '../uires/logos/pytorch-logo-icon.svg';

export const getWAString = (
	msg = `Hey Aditya! Saw your developer portfolio. Would love to talk to you for a project. Drop me a message when you're free.`,
	number = '917678114688'
) => {
	// const number = '917678114688';

	// const msg_original = `Hey Aditya! Saw your developer portfolio. Would love to talk to you for a project. Drop me a message when you're free.`;
	// const msg = `Hey%20Aditya!%20Saw%20your%20developer%20portfolio.%20Would%20love%20to%20talk%20to%20you%20for%20a%20project.%20Drop%20me%20a%20message%20when%20you're%20free.`
	return `https://api.whatsapp.com/send?phone=` + number + `&text=` + msg + '';
};

const port = {
	g_name: `Aditya`,
	f_name: `Mishra`,
	land_greeting: `Hi there!`,
	land_intro: [
		`I'm an entrepreneur `,
		`& I want to make your life `,
		`easier - with technology.`,
	],
	designation_top: ``,
	designation_current: ``,
	roles: [
		{ id: 1, label: `entrepreneur`, color: 'yellow', after: '/' },
		{ id: 2, label: `developer`, color: 'royalblue', after: '/' },
		{ id: 3, label: `designer`, color: 'rebeccapurple', after: '' },
		// { id: 4, label: `evangelist`, color: 'green', after: '/' },
		// { id: 5, label: `educationist`, color: 'red', after: '/>' },
	],
	intro_profile: [
		`I like to build (or optimze) products and services which customers can access and enjoy. My creed is "to use technology for making life easier and happier." And I'm always looking for innovators, philosophers, to befriend.`,
		`For the past year I've been following my passion to build a professional network - which will provide affordable promotion & skill education to creators and builders, optimized to help students and self-starters.`,
		`We've been hosting free workshops for college students in Delhi-NCR and It's a work in progress. And frankly we've been unable to raise funds yet to support the wonderful team.`,
		`Ultimately my goal is to support startups and self-starters with the skills and experience I have so while I'm unable to launch the platform, I'm still going to do my best to assist you personally.`,
		`I'm available for hire and would love to talk to you about your startup.`,
		`If you are looking for a consult, just ask me over for a cup of coffee anywhere in Delhi.`,
	],
	services: [
		{
			id: 1,
			short: `Web Dev`,
			intro: `build a website`,
			title: `Web App Development`,
			title_long: 'Full Stack Web Developer',
			eg: `eg. landing page, home page, blog, portfolio, resume, etc.`,
			description: `"Get fast and scalable websites to take your business online in a timely, cost efficient way."`,
			cta: `Build me a website`,
			cta_msg: `Hey Aditya! Saw your developer portfolio. I need you to build a website for me. Drop me a message when you're free. `,
			projects: [
				`44`,
				`43`,
				`42`,
				`41`,
				`40`,
				`33`,
				`32`,
				`31`,
				`30`,
				`1`,
				`2`,
				`3`,
				`4`,
				`8`,
				`9`,
			],
			img_src: `https://images.unsplash.com/photo-1507238691740-187a5b1d37b8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1600`,
			img_cred: '',
		},
		{
			id: 2,
			short: `App Dev`,
			intro: `build a mobile app`,
			title: `Android App Development`,
			title_long: 'Android App Developer',
			eg: `eg. Personal, Event, eStore, etc.`,
			description: `"Get fast and intuitive mobile apps to put your services into the pockets of your users."`,
			cta: `Build me an App`,
			cta_msg: `Hey Aditya! Saw your developer portfolio. I need you to build an Android App for me. Drop me a message when you're free. `,
			projects: [`1`, `4`, `5`, `6`, `7`, `10`, `11`, `12`, `13`],
			img_src: `https://images.unsplash.com/photo-1551650975-87deedd944c3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1600`,
			img_cred: '',
		},
		{
			id: 3,
			short: `Design`,
			intro: `design inspired graphics`,
			title: `Design`,
			title_long: 'Human Centric Graphics Designer',
			eg: `eg. App UI/UX, Web UI/UX, Posters, Book Covers, Logos, Visiting Cards, Leaflets, Prospectus, etc.`,
			description: `"Get inspired pieces of graphics to bring joy to your target users and earn their attention, giving your brand the unique identity it deserves."`,
			cta: `Build my visual brand`,
			cta_msg: `Hey Aditya! Saw your design portfolio. I need you to design some graphics for me. Drop me a message when you're free. `,
			projects: [
				`15`,
				`16`,
				`17`,
				`18`,
				`19`,
				`20`,
				`21`,
				`22`,
				`23`,
				`24`,
				`25`,
				`26`,
				`27`,
			],
			img_src: `https://images.unsplash.com/photo-1539883305165-f691affc6c54?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1600`,
			img_cred: '',
		},
		{
			id: 4,
			short: `Branding`,
			intro: `do social media marketing`,
			title: `Social Media Marketing`,
			title_long: 'Social Media Evangelist',
			eg: `eg. Facebook Pages & Groups, Instagram, Twitter, LinkedIn`,
			description: `"With my experience in designing targeted engaging experiences get engagement strategies and content to build your venture into a social brand"`,
			cta: `Build my social brand`,
			cta_msg: `Hey Aditya! Saw your marketing portfolio. I need you to help me build by brand and market it. Drop me a message when you're free. `,
			projects: [`2`, `4`, `5`, `15`, `16`, `19`, `24`, `25`],
			img_src: `https://images.unsplash.com/photo-1432888622747-4eb9a8efeb07?ixlib=rb-1.2.1&auto=format&fit=crop&w=1600`,
			img_cred: '',
		},
	],
	blog: [
		{
			id: '0',
			title: 'Delight Your Clients into Relationships',
			link: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=99&w=1200&auto=format&fit=crop',
			img_src:
				'https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=600&auto=format&fit=crop',
			timestamp: '26th June 2024',
		},
		{
			id: '1',
			title: 'Design is more than just how it looks',
			link: 'https://images.unsplash.com/photo-1476357471311-43c0db9fb2b4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
			img_src:
				'https://images.unsplash.com/photo-1476357471311-43c0db9fb2b4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=60',
			timestamp: '3rd September 2019',
		},
		{
			id: '2',
			title: 'Taking design decisions with your customers',
			link: '',
			img_src:
				'https://images.unsplash.com/photo-1557425747-929b65a39785?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=60',
			timestamp: '3rd October 2019',
		},
		{
			id: '3',
			title: 'Mythical Designs that make everyone happy!',
			link: '',
			img_src:
				'https://images.unsplash.com/photo-1522542550221-31fd19575a2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=80',
			timestamp: '3rd November 2019',
		},
	],
	skills: [
		{
			id: `1`,
			name: `Front End Development`,
			start_year: 2013,
			last_used: 2019,
			projects: [],
			icon_src: ``,
		},
		{
			id: `2`,
			name: `Back End Development`,
			start_year: 2016,
			last_used: 2019,
			projects: [],
			icon_src: ``,
		},
		{
			id: `3`,
			name: `Android App Development`,
			start_year: 2014,
			last_used: 2019,
			projects: [],
			icon_src: ``,
		},
		{
			id: `4`,
			name: `Progressive Web App (PWA) Development`,
			start_year: 2018,
			last_used: 2019,
			projects: [],
			icon_src: ``,
		},
		{
			id: `5`,
			name: `Natural Language Processing Development`,
			start_year: 2016,
			last_used: 2017,
			projects: [],
			icon_src: ``,
		},
		{
			id: `6`,
			name: `User Centred Design (UCD/HCD)`,
			start_year: 2017,
			last_used: 2019,
			projects: [],
			icon_src: ``,
		},
		{
			id: `7`,
			name: `Graphics Design`,
			start_year: 2016,
			last_used: 2019,
			projects: [],
			icon_src: ``,
		},
		{
			id: `8`,
			name: `UI Design`,
			start_year: 2016,
			last_used: 2019,
			projects: [],
			icon_src: ``,
		},
		{
			id: `9`,
			name: `UX Design`,
			start_year: 2016,
			last_used: 2019,
			projects: [],
			icon_src: ``,
		},
		{
			id: `10`,
			name: `Teaching`,
			start_year: 2014,
			last_used: 2019,
			projects: [],
			icon_src: ``,
		},
		{
			id: `11`,
			name: `Data Analytics`,
			start_year: 2017,
			last_used: 2019,
			projects: [],
			icon_src: ``,
		},
		{
			id: `12`,
			name: `Market Research`,
			start_year: 2014,
			last_used: 2019,
			projects: [],
			icon_src: ``,
		},
		{
			id: `13`,
			name: `Social Media Marketing`,
			start_year: 2014,
			last_used: 2019,
			projects: [],
			icon_src: ``,
		},
		{
			id: `14`,
			name: `Team Management`,
			start_year: 2014,
			last_used: 2019,
			projects: [],
			icon_src: ``,
		},
		{
			id: `15`,
			name: `Project Management`,
			start_year: 2016,
			last_used: 2019,
			projects: [],
			icon_src: ``,
		},
		{
			id: `16`,
			name: `Event Management`,
			start_year: 2009,
			last_used: 2019,
			projects: [],
			icon_src: ``,
		},
		{
			id: `17`,
			name: `Game Development`,
			start_year: 2013,
			last_used: 2015,
			projects: [],
			icon_src: ``,
		},
	],
	tech: [
		{
			id: `1`,
			name: `HTML & CSS`,
			start_year: 2005,
			last_used: 2019,
			projects: [1, 2, 3, 4, 8],
			icon_src: logo_html,
		},
		{
			id: `2`,
			name: `Javascript`,
			start_year: 2005,
			last_used: 2019,
			projects: [],
			icon_src: logo_js,
		},
		{
			id: `3`,
			name: `C++`,
			start_year: 2011,
			last_used: 2016,
			projects: [],
			icon_src: logo_cpp,
		},
		{
			id: `4`,
			name: `C`,
			start_year: 2013,
			last_used: 2015,
			projects: [],
			icon_src: logo_clang,
		},
		{
			id: `5`,
			name: `Java`,
			start_year: 2013,
			last_used: 2019,
			projects: [],
			icon_src: null,
		},
		{
			id: `6`,
			name: `Android`,
			start_year: 2001,
			last_used: 2019,
			projects: [],
			icon_src: logo_android,
		},
		{
			id: `7`,
			name: `Angular.js`,
			start_year: 2016,
			last_used: 2018,
			projects: [],
			icon_src: logo_ang,
		},
		{
			id: `8`,
			name: `React.js`,
			start_year: 2017,
			last_used: 2019,
			projects: [],
			icon_src: logo_react,
		},
		{
			id: `9`,
			name: `Node.js`,
			start_year: 2016,
			last_used: 2019,
			projects: [],
			icon_src: logo_node,
		},
		{
			id: `10`,
			name: `Python`,
			start_year: 2016,
			last_used: 2019,
			projects: [],
			icon_src: logo_python,
		},
		{
			id: `11`,
			name: `Django`,
			start_year: 2016,
			last_used: 2017,
			projects: [],
			icon_src: null,
		},
		{
			id: `12`,
			name: `SQL`,
			start_year: 2014,
			last_used: 2017,
			projects: [],
			icon_src: ``,
		},
		{
			id: `13`,
			name: `MongoDB`,
			start_year: 2016,
			last_used: 2019,
			projects: [],
			icon_src: ``,
		},
		{
			id: `14`,
			name: `NoSQL`,
			start_year: 2016,
			last_used: 2019,
			projects: [],
			icon_src: ``,
		},
		{
			id: `15`,
			name: `NLTK`,
			alt: `Natural Language Tool Kit`,
			start_year: 2015,
			last_used: 2019,
			projects: [],
			icon_src: ``,
		},
		// {
		// 	id: `16`,
		// 	name: `TensorFlow`,
		// 	start_year: 2019,
		// 	last_used: 2019,
		// 	projects: [],
		// 	icon_src: logo_tf,
		// },
		// {
		// 	id: `17`,
		// 	name: `PyTorch`,
		// 	start_year: 2019,
		// 	last_used: 2019,
		// 	projects: [],
		// 	icon_src: logo_pytorch,
		// },
	],
	projects: [
		{
			id: `1`,
			started: 2019,
			completed: `Ongoing`,
			title: `QuirkNetwork`,
			description: `Professional social media platform for the next billion creators on the internet`,
			tech_used: [`1`, `2`, `8`, `9`, `14`, `15`],
			skills_used: [
				`1`,
				`2`,
				`4`,
				`5`,
				`6`,
				`7`,
				`8`,
				`9`,
				`11`,
				`12`,
				`13`,
				`14`,
				`15`,
			],
			team_size: 4,
			client_name: `FGC`,
			designation: `CEO, CTO, Lead Engineer`,
			tags: [
				`Social Media`,
				`Startup`,
				`Founders`,
				`Project Management`,
				`Creators`,
				`Products & Services`,
				`Social Networking App`,
			],
			pic_src: ``,
		},
		{
			id: `2`,
			started: 2019,
			completed: `Ongoing`,
			title: `TDC Reach Workshops`,
			description: `Workshops on fundamentals of startups conducted for college students of undergraduate & graduation courses in Delhi. Topics covered included`,
			tech_used: [],
			skills_used: [`6`, `7`, `10`, `12`, `14`, `16`],
			team_size: 3,
			client_name: `FGC`,
			designation: `Course Designer & Lead Instructor`,
			tags: [`Startup`, `Founders`, `Students`],
			pic_src: ``,
		},
		{
			id: `3`,
			started: 2019,
			completed: `Ongoing`,
			title: `TDC Works`,
			description: `Training & Placement Platform for students and startups`,
			tech_used: [`1`, `2`, `8`, `14`],
			skills_used: [`1`, `4`, `6`, `7`, `8`, `9`, `12`, `16`],
			team_size: 4,
			client_name: `FGC`,
			designation: `Lead Engineer`,
			tags: [`Startup`, `Hiring`, `Students`, `Training`, `Placement`],
			pic_src: ``,
		},
		{
			id: `4`,
			started: 2017,
			completed: 2018,
			title: `TDC Elevate`,
			description: `Elevator Pitch event for student startups`,
			tech_used: [`1`, `2`, `6`],
			skills_used: [`1`, `3`, `6`, `7`, `8`, `9`, `11`, `12`, `13`, `14`, `16`],
			team_size: 14,
			client_name: `FGC`,
			designation: `Organiser`,
			tags: [`Startup`, `Founders`, `Students`, `Investments`],
			pic_src: ``,
		},
		{
			id: `5`,
			started: 2017,
			completed: `Shelved`,
			title: `ForSocial`,
			description: `Crowdsourcing platform for non-profits to solve the last mile audit problems`,
			tech_used: [`1`, `2`, `6`, `8`, `9`, `10`, `14`],
			skills_used: [
				`1`,
				`2`,
				`3`,
				`4`,
				`6`,
				`7`,
				`8`,
				`9`,
				`11`,
				`12`,
				`13`,
				`14`,
				`15`,
			],
			team_size: 28,
			client_name: `FGC`,
			designation: `Lead Engineer`,
			tags: [
				`Social Good`,
				`NOGs`,
				`Crowd Sourcing`,
				`Last Mile Audit`,
				`Block Chain`,
				`Fintech`,
			],
			pic_src: ``,
		},
		{
			id: `6`,
			started: 2017,
			completed: 2017,
			title: `Warp`,
			description: `Hyperlocal Marketplace built on Blockchain based smart contracts. Led a team of developers to build a set of 3 android applications to facilitate a supplychain ecosystem powered by the smart contracts.`,
			tech_used: [`5`, `6`, `14`],
			skills_used: [`1`, `3`, `8`, `9`, `14`, `15`],
			team_size: 6,
			client_name: `code: Alpha`,
			designation: `Lead Engineer`,
			tags: [
				`Aggregation`,
				`Inventory Management`,
				`Blockchain`,
				`Smart Contracts`,
				`PaaS`,
			],
			pic_src: ``,
		},
		{
			id: `7`,
			started: 2016,
			completed: 2016,
			title: `ClothesLine`,
			description: `A textile industry specific news brief app. Built on top of a PHP backend.`,
			tech_used: [`5`, `6`, `12`],
			skills_used: [`3`, `8`, `9`, `15`],
			team_size: 4,
			client_name: `code: Alpha`,
			designation: `Android Developer`,
			tags: [`Textile`, `News Aggregation`, `Content Management System`],
			pic_src: ``,
		},
		{
			id: `8`,
			started: 2016,
			completed: 2017,
			title: `Klinic`,
			description: `A biomedical Query andswerig & research tool built on NLP in a team of 4`,
			tech_used: [`1`, `2`, `10`, `11`, `13`, `15`],
			skills_used: [`1`, `2`, `5`, `6`, `7`, `8`, `9`, `12`, `14`, `15`],
			team_size: 4,
			client_name: `FGC`,
			designation: `Lead Engineer`,
			tags: [`NLP`, `AI`, `Doctors`, `Healthcare`],
			pic_src: ``,
		},
		{
			id: `9`,
			started: 2016,
			completed: 2016,
			title: `Jaago Bharat`,
			description: `A data visualization and analytics platform build on the data and APIs from GoIs Open Data Initiative built over a week`,
			tech_used: [`1`, `2`, `7`, `9`, `13`],
			skills_used: [`1`, `2`, `7`, `11`, `14`, `15`],
			team_size: 4,
			client_name: `ASET, Delhi`,
			designation: ``,
			tags: [`Data Visualization`, `Open Data (GoI)`, `Data Interpretation`],
			pic_src: ``,
		},
		{
			id: `10`,
			started: 2015,
			completed: 2015,
			title: `HotBrew`,
			description: `An anonymous messaging platform for workplace transparency and equality built in an overnight hackathon in a team of 2`,
			tech_used: [`5`, `6`, `14`],
			skills_used: [`1`, `3`, `6`, `7`, `8`, `9`, `14`, `15`],
			team_size: 2,
			client_name: `Personal Project`,
			designation: ``,
			tags: [
				`Communication`,
				`Chat`,
				`Collaboration`,
				`Workplace`,
				`empowering EMployees`,
			],
			pic_src: ``,
		},
		{
			id: `11`,
			started: 2010,
			completed: 2010,
			title: `Amitech 17`,
			description: `Official Fest App to coordinate sponsorship and event publicity efforts of the college`,
			tech_used: [`5`, `6`],
			skills_used: [`3`, `6`, `7`, `8`, `9`, `14`, `15`, `16`],
			team_size: 16,
			client_name: `ASET, Delhi`,
			designation: `App Master`,
			tags: [`Event Management`, `Event Info`],
			pic_src: ``,
		},
		{
			id: `12`,
			started: 2010,
			completed: 2010,
			title: `Amitech 16`,
			description: `Official Fest App boost fest publicity and registration efforts of the college`,
			tech_used: [`5`, `6`],
			skills_used: [`3`, `6`, `7`, `8`, `9`, `14`, `15`, `16`],
			team_size: 3,
			client_name: `ASET, Delhi`,
			designation: `App Master`,
			tags: [`Event Info`],
			pic_src: ``,
		},
		{
			id: `13`,
			started: 2013,
			completed: 2014,
			title: `eCheck`,
			description: `Project made to automate and digitize the traditional ticketing procedure before RailYatri was prominent`,
			tech_used: [`5`, `6`, `14`],
			skills_used: [`3`, `12`, `14`],
			team_size: 3,
			client_name: `ASET, Delhi`,
			designation: `Android Developer`,
			tags: [`Railways`, `Passengers`],
			pic_src: ``,
		},
		{
			id: `14`,
			started: 2010,
			completed: 2010,
			title: `Mémoire App`,
			description: `Android app to celebrate personal events and memories, before google photos was cool.`,
			tech_used: [`5`, `6`],
			skills_used: [`3`, `7`],
			team_size: 1,
			client_name: `Practice Project`,
			designation: `Lead Developer`,
			tags: [`Personal Wellness`],
			pic_src: ``,
		},
		{
			id: `15`,
			started: 2018,
			completed: 2019,
			title: `MegaVisors Online Promotion`,
			description: `Handled social media marketing, content creation and strategic marekting for the CA firm MegaVisors`,
			tech_used: [null],
			skills_used: [`7`, `12`, `13`, `14`],
			team_size: 3,
			client_name: `MegaVisors`,
			designation: `Freelancer`,
			tags: [`Digital Marketing`, `Social Media Marketing`, `Content Creation`],
			pic_src: ``,
		},
		{
			id: `16`,
			started: 2010,
			completed: 2010,
			title: `Modet Apps & Marketing`,
			description: `Design and Marketing consultancy for public sector professional services chain`,
			tech_used: [null],
			skills_used: [`7`, `8`, `9`, `12`, `13`],
			team_size: 3,
			client_name: `Modet`,
			designation: `UI/UX Consultant`,
			tags: [
				`Digital Marketing`,
				`Social Media Marketing`,
				`Content Creation`,
				`Professional Services`,
			],
			pic_src: ``,
		},
		{
			id: `17`,
			started: 2010,
			completed: 2010,
			title: `Modwin Design Consultation`,
			description: `Logo design consultancy for public healthcare chain`,
			tech_used: [null],
			skills_used: [`7`, `8`, `9`],
			team_size: 3,
			client_name: `Modwin Healthcare`,
			designation: `Principle Design Consultant`,
			tags: [`Professional Services`, `Strategic Design`],
			pic_src: ``,
		},
		{
			id: `18`,
			started: 2010,
			completed: 2010,
			title: `BonteZone UI/UX & Branding`,
			description: `Logo & Interface Design for organic food ecommerce store`,
			tech_used: [null],
			skills_used: [`7`, `8`, `9`],
			team_size: 3,
			client_name: `BonteZone`,
			designation: `Freelance Designer`,
			tags: [`Professional Services`, `Strategic Design`],
			pic_src: ``,
		},
		{
			id: `19`,
			started: 2010,
			completed: 2010,
			title: `BrannoTech Branding`,
			description: `Logo & Brand Voice design for software development services chain`,
			tech_used: [null],
			skills_used: [`7`, `8`, `9`],
			team_size: 3,
			client_name: `BonteZone`,
			designation: `Freelance Designer`,
			tags: [`Professional Services`, `Strategic Design`],
			pic_src: ``,
		},
		{
			id: `20`,
			started: 2018,
			completed: 2018,
			title: `MoBullz Logo Design`,
			description: ``,
			tech_used: [null],
			skills_used: [`7`],
			team_size: 1,
			client_name: `code: MoBullz`,
			designation: ``,
			tags: [],
			pic_src: ``,
		},
		{
			id: `21`,
			started: 2017,
			completed: 2018,
			title: `Book Cover Design - ITI`,
			description: `Designed covers for competitive examination preparation books from ITI to Management Level exams`,
			tech_used: [null],
			skills_used: [`7`, `12`],
			team_size: 1,
			client_name: `code: Beta`,
			designation: ``,
			tags: [],
			pic_src: ``,
		},
		{
			id: `22`,
			started: 2010,
			completed: 2010,
			title: `Book Cover Design - 9th-12th`,
			description: `Desinged covers for PCMB subject books for NCERT based books distributed in remote regions`,
			tech_used: [null],
			skills_used: [`7`],
			team_size: 1,
			client_name: `code: Gamma`,
			designation: ``,
			tags: [],
			pic_src: ``,
		},
		{
			id: `23`,
			started: 2010,
			completed: 2010,
			title: `Prospectus Design & Marketing Consultation`,
			description: ``,
			tech_used: [null],
			skills_used: [`7`, `12`],
			team_size: 1,
			client_name: `code: Omega`,
			designation: ``,
			tags: [],
			pic_src: ``,
		},
		{
			id: `24`,
			started: 2018,
			completed: 2018,
			title: `Content Design & SMM for IITD Startup Basecamp`,
			description: ``,
			tech_used: [null],
			skills_used: [`7`, `12`],
			team_size: 3,
			client_name: `IIT Delhi Startup Basecamp`,
			designation: `Designer`,
			tags: [],
			pic_src: ``,
		},
		{
			id: `25`,
			started: 2018,
			completed: 2018,
			title: `Content Design & SMM for TDC participants' App`,
			description: ``,
			tech_used: [null],
			skills_used: [`7`, `12`],
			team_size: 3,
			client_name: `Titanic App`,
			designation: `Designer`,
			tags: [],
			pic_src: ``,
		},
		{
			id: `26`,
			started: 2013,
			completed: 2016,
			title: `Poster & Banner Design for Tredbook`,
			description: ``,
			tech_used: [null],
			skills_used: [`7`],
			team_size: 2,
			client_name: `TredBook`,
			designation: `Designer`,
			tags: [],
			pic_src: ``,
		},
		{
			id: `27`,
			started: 2024,
			completed: 2024,
			title: `Logo Design for KPC Construction`,
			description: ``,
			tech_used: [null],
			skills_used: [`7`],
			team_size: 2,
			client_name: `KPC Construction`,
			designation: `Designer`,
			tags: [],
			pic_src: ``,
		},
		{
			id: `30`,
			started: 2022,
			completed: 2022,
			title: `GamerPe`,
			link: `https://gamerpe.com/`,
			description: `Professional social media platform for the next billion creators on the internet`,
			tech_used: [`1`, `2`, `8`, `9`, `14`, `15`],
			skills_used: [
				`1`,
				`2`,
				`4`,
				`5`,
				`6`,
				`7`,
				`8`,
				`9`,
				`11`,
				`12`,
				`13`,
				`14`,
				`15`,
			],
			team_size: 15,
			client_name: `GamingAce Technologies Pvt. Ltd.`,
			designation: `Product Manager`,
			tags: [
				`Social Media`,
				`Startup`,
				`Founders`,
				`Project Management`,
				`Creators`,
				`Products & Services`,
				`Social Networking App`,
			],
			pic_src: ``,
		},
		{
			id: `31`,
			started: 2022,
			completed: 2024,
			title: `SocialPi`,
			link: `https://socialpi.ai/`,
			description: `No Cost Earning tools for Content Creators`,
			tech_used: [`1`, `2`, `8`, `9`, `14`, `15`],
			skills_used: [
				`1`,
				`2`,
				`4`,
				`5`,
				`6`,
				`7`,
				`8`,
				`9`,
				`11`,
				`12`,
				`13`,
				`14`,
				`15`,
			],
			team_size: 4,
			client_name: `Brand Influencer Media Pvt. Ltd.`,
			designation: `CTO`,
			tags: [
				`Social Media`,
				`Startup`,
				`Founders`,
				`Project Management`,
				`Creators`,
				`Products & Services`,
				`Social Networking App`,
			],
			pic_src: ``,
		},
		{
			id: `32`,
			started: 2022,
			completed: 2024,
			title: `AffiPi`,
			link: `https://affipi.com/`,
			description: `No Cost Affiliate Marketing platform for Content Creators`,
			tech_used: [`1`, `2`, `8`, `9`, `14`, `15`],
			skills_used: [
				`1`,
				`2`,
				`4`,
				`5`,
				`6`,
				`7`,
				`8`,
				`9`,
				`11`,
				`12`,
				`13`,
				`14`,
				`15`,
			],
			team_size: 4,
			client_name: `Brand Influencer Media Pvt. Ltd.`,
			designation: `CTO`,
			tags: [
				`Social Media`,
				`Startup`,
				`Founders`,
				`Project Management`,
				`Creators`,
				`Products & Services`,
				`Social Networking App`,
			],
			pic_src: ``,
		},
		{
			id: `33`,
			started: 2024,
			completed: 2024,
			title: `GramHealth`,
			link: `https://gramhealth.in/`,
			description: `No Cost Earning tools for Content Creators`,
			tech_used: [`1`, `2`, `8`, `9`, `14`, `15`],
			skills_used: [
				`1`,
				`2`,
				`4`,
				`5`,
				`6`,
				`7`,
				`8`,
				`9`,
				`11`,
				`12`,
				`13`,
				`14`,
				`15`,
			],
			team_size: 4,
			client_name: `GramHelath Pvt. Ltd.`,
			designation: `CTO`,
			tags: [
				`Social Media`,
				`Startup`,
				`Founders`,
				`Project Management`,
				`Creators`,
				`Products & Services`,
				`Social Networking App`,
			],
			pic_src: ``,
		},
		{
			id: `40`,
			started: 2024,
			completed: `Ongoing`,
			title: `Front Page Social`,
			description: `Professional social media platform for the next billion creators on the internet`,
			tech_used: [`1`, `2`, `8`, `9`, `14`, `15`],
			skills_used: [
				`1`,
				`2`,
				`4`,
				`5`,
				`6`,
				`7`,
				`8`,
				`9`,
				`11`,
				`12`,
				`13`,
				`14`,
				`15`,
			],
			team_size: 4,
			client_name: `FGC`,
			designation: `CEO, CTO, Lead Engineer`,
			tags: [
				`Social Media`,
				`Startup`,
				`Founders`,
				`Project Management`,
				`Creators`,
				`Products & Services`,
				`Social Networking App`,
			],
			pic_src: ``,
		},
		{
			id: `41`,
			started: 2024,
			completed: `Ongoing`,
			title: `Aryse Academy`,
			description: `Content Creation repository for the next billion creators on the internet`,
			tech_used: [`1`, `2`, `8`, `9`, `14`, `15`],
			skills_used: [
				`1`,
				`2`,
				`4`,
				`5`,
				`6`,
				`7`,
				`8`,
				`9`,
				`11`,
				`12`,
				`13`,
				`14`,
				`15`,
			],
			team_size: 4,
			client_name: `FGC`,
			designation: `CEO, CTO, Lead Engineer`,
			tags: [
				`Social Media`,
				`Startup`,
				`Founders`,
				`Project Management`,
				`Creators`,
				`Products & Services`,
				`Social Networking App`,
			],
			pic_src: ``,
		},
		{
			id: `42`,
			started: 2024,
			completed: `Ongoing`,
			title: `Brew Jobs`,
			link: `https://brewjoys.com/`,
			description: `Job Hunt made transparent`,
			tech_used: [`1`, `2`, `8`, `9`, `14`, `15`],
			skills_used: [
				`1`,
				`2`,
				`4`,
				`5`,
				`6`,
				`7`,
				`8`,
				`9`,
				`11`,
				`12`,
				`13`,
				`14`,
				`15`,
			],
			team_size: 4,
			client_name: `FGC`,
			designation: `CEO, CTO, Lead Engineer`,
			tags: [
				`Social Media`,
				`Startup`,
				`Founders`,
				`Project Management`,
				`Creators`,
				`Products & Services`,
				`Social Networking App`,
			],
			pic_src: ``,
		},
		{
			id: `43`,
			started: 2024,
			completed: `Ongoing`,
			title: `Warranty Buddy`,
			link: null,
			description: `Warranty Management and Repair Support System`,
			tech_used: [`1`, `2`, `8`, `9`, `14`, `15`],
			skills_used: [
				`1`,
				`2`,
				`4`,
				`5`,
				`6`,
				`7`,
				`8`,
				`9`,
				`11`,
				`12`,
				`13`,
				`14`,
				`15`,
			],
			team_size: 4,
			client_name: `FGC`,
			designation: `CEO, CTO, Lead Engineer`,
			tags: [
				`Social Media`,
				`Startup`,
				`Founders`,
				`Project Management`,
				`Creators`,
				`Products & Services`,
				`Social Networking App`,
			],
			pic_src: ``,
		},
		{
			id: `44`,
			started: 2024,
			completed: `Ongoing`,
			title: `Joys of Work`,
			link: 'https://joysof.work/',
			description: `Warranty Management and Repair Support System`,
			tech_used: [`1`, `2`, `8`, `9`, `14`, `15`],
			skills_used: [
				`1`,
				`2`,
				`4`,
				`5`,
				`6`,
				`7`,
				`8`,
				`9`,
				`11`,
				`12`,
				`13`,
				`14`,
				`15`,
			],
			team_size: 4,
			client_name: `FGC`,
			designation: `CEO, CTO, Lead Engineer`,
			tags: [
				`Social Media`,
				`Startup`,
				`Founders`,
				`Project Management`,
				`Creators`,
				`Products & Services`,
				`Social Networking App`,
			],
			pic_src: ``,
		},

		// {
		// 	id: `27`,
		// 	started: 2013,
		// 	completed: 2015,
		// 	title: `Infinite Runner Game Development`,
		// 	description: `Made a game with open sourced sprites and environments on Unity 3D.`,
		// 	tech_used: [`2`, `3`, `14`],
		// 	skills_used: [],
		// 	team_size: ``,
		// 	client_name: `TredBook`,
		// 	designation: ``,
		// 	tags: [],
		// 	pic_src: ``,
		// },
		// {
		// 	id: `28`,
		// 	started: 2006,
		// 	completed: `Ongoing`,
		// 	title: `Smaller Practice Projects`,
		// 	description: `Various web, app and console based projects to practice and test my skills`,
		// 	tech_used: [`1`, `2`, `3`, `4`, `5`, `12`, `16`, `17`],
		// 	skills_used: [`1`, `2`, `3`, `6`, `7`, `8`, `15`],
		// 	team_size: ``,
		// 	client_name: `TredBook`,
		// 	designation: ``,
		// 	tags: [],
		// 	pic_src: ``,
		// },
	],
	research: [
		{
			id: `1`,
			started: ``,
			completed: ``,
			title: ``,
			description: ``,
			tech_used: [`1`, `2`, `10`, `11`, `13`],
			team_size: `4`,
			published: ``,
			publication_year: `2017`,
			tags: ``,
			pic_src: ``,
		},
		{
			id: `2`,
			started: ``,
			completed: ``,
			title: ``,
			description: ``,
			tech_used: [`1`, `2`, `10`, `11`, `13`],
			team_size: `4`,
			published: `NA`,
			publication_year: `NA`,
			tags: ``,
			pic_src: ``,
		},
	],
};

export default port;
